<template>
	<div ref="header">
		<div class="hero-image" :class="{ subpage: !route.name.startsWith('index') }">
			<slot name="heroContent" />
			<picture>
				<source
					v-if="headingImage.imagePortraitWebp"
					:srcset="headingImage.imagePortraitWebp"
					type="image/webp"
					media="(max-width: 500px)"
				/>
				<source
					v-if="headingImage.imagePortrait"
					:srcset="headingImage.imagePortrait"
					media="(max-width: 500px)"
				/>
				<source :srcset="headingImage.imageWebp" type="image/webp" />
				<source :srcset="headingImage.image" />
				<img class="slider-image" :src="headingImage.image" :alt="headingImage.imageAlt" />
			</picture>
			<a v-if="route.name.startsWith('index')" class="scroll-down smooth-scroll" @click="scrollDown()" />
		</div>
	</div>
</template>

<script setup>
const route = useRoute();

const header = ref(null);
const scrollDown = () => {
	const scrollHeight = header.value.clientHeight;
	window.scroll({
		top: scrollHeight,
		left: 0,
		behavior: 'smooth',
	});
};

defineProps({
	headingImage: { type: Object, default: () => {} },
});
</script>

<style lang="scss" scoped>
.hero-image {
	width: 100%;
	height: 100vh;
	position: relative;
	overflow: hidden;

	.slider-image {
		transition: all 0.3s ease-in-out;
		animation: zoom-in 9800ms ease-in-out forwards;
	}
}

.hero-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.subpage.hero-image {
	height: 50vh;
}

.hero-image::before {
	content: '';
	z-index: 2;
	position: absolute;
	display: block;
	inset: 0;
	background: rgba(0 0 0 / 20%);
}

.news-item .hero-image::before {
	display: none;
}

.scroll-down {
	position: absolute;
	bottom: 50px;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	z-index: 4;
	cursor: pointer;

	&:hover img {
		transform: scale(1.1);
	}

	img {
		width: 47px;
		height: 47px;
		transition: all 0.3s ease-in-out;
	}
}

@keyframes zoom-in {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.12);
	}
}

@media (max-width: 880px) {
	.scroll-down {
		bottom: 30px;
	}
}

@media (max-width: 680px) {
	.scroll-down {
		bottom: 20px;
	}
}

@media (max-width: 420px) {
	.scroll-down {
		bottom: 15px;
	}
}
</style>
